import React, { PropsWithChildren } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Recipe from '../../../modules/recipe/pages/recipe';
import Ingredient from '../../../modules/ingredients/pages/ingredient';
import { compose } from 'recompose';
import PublicNavbar from '../partials/headers/publicNavBar';
import Footer from '../partials/Footer';
import { ILayoutProps } from './layout.interfaces';
import { AppState } from '../../store';
import RecipeForm from '../../../modules/recipe/pages/addRecipe';
import CategoryTagTypes from '../../../modules/categoryTagTypes/pages/categoryTagTypes';
import CategoryTag from '../../../modules/categoryTags/pages/categoryTag';
import ActionName from '../../../modules/actionNames/pages/actionName';
import CookingMethods from '../../../modules/cookingMethods/pages/cookingMethods';
import Tutorial from '../../../modules/tutorials/pages/tutorial';
import { cookingMethodsEndpoints } from '../../../modules/cookingMethods/cooking.methods.endpoints';
import { ingredientsEndpoints } from '../../../modules/ingredients/ingredients.endpoints';
import { categoryTagsEndpoints } from '../../../modules/categoryTags/category.tags.endpoints';
import { categoryTagTypesEndpoints } from '../../../modules/categoryTagTypes/category.tag.types.endpoints';
import { actionNamesEndpoints } from '../../../modules/actionNames/action.names.endpoints';
import AddOrEditTutorial from '../../../modules/tutorials/pages/addOrEditTutorial';
import Influencer from '../../../modules/influencers/pages/influencers';
import InfluencerForm from '../../../modules/influencers/pages/addOrEditInfluencer';
import { influencerConstants } from '../../../modules/influencers/influencer.constants';
import Product from '../../../modules/upselling/pages/product';
import ProductForm from '../../../modules/upselling/pages/addOrEditProduct';
import { productConstants } from '../../../modules/upselling/upselling.constants';
import { recipeConstants } from '../../../modules/recipe/recipe.constants';
import { tutorialConstants } from '../../../modules/tutorials/tutorial.constants';
import { unitConstants } from '../../../modules/unitManagement/unit.constants';
import { equipmentConstants } from '../../../modules/equipment/equipment.constants';
import Equipment from '../../../modules/equipment/pages';
import Units from '../../../modules/unitManagement/pages';
import { filterConstants } from '../../../modules/filters/filter.constants';
import Filter from '../../../modules/filters/pages/filter';
import AddOrEditFilter from '../../../modules/filters/pages/addOrEditFilter';
import { selectionConstants } from '../../../modules/selection/selection.constants';
import Selections from '../../../modules/selection/pages/selection';
import AddOrEditSelection from '../../../modules/selection/pages/addOrEditSelection';
import { actionNamesConstants } from '../../../modules/actionNames/action.names.constants';
import { reviewConstants } from '../../../modules/reviews/reviews.constants';
import Review from '../../../modules/reviews/pages';
import EditBlacklist from '../../../modules/reviews/pages/editBlacklist';
import { promotionCampaignEndpoints } from '../../../modules/campaigns/promotion.campaign.endpoints';
import PromotionCampaign from '../../../modules/campaigns/pages/promotionCampaign';
import AddOrEditPromotionCampaign from '../../../modules/campaigns/pages/addOrEditPromotionCampaign';
import { userReportConstants } from '../../../modules/userReports/user.report.constants';
import ReviewReportList from '../../../modules/userReports/reviewReportList';
import UserReports from '../../../modules/userReports/userReports';
import ReportsForUser from '../../../modules/userReports/reportsForUser';
import ReferralOrders from '../../../modules/referralOrders/pages';
import { referralOrderConstants } from '../../../modules/referralOrders/referral.order.constants';
import { quizQuestionEndpoints } from '../../../modules/quizQuestions/quiz.questions.endpoints';
import AddOrEditQuizQuestion from '../../../modules/quizQuestions/pages/addOrEditQuizQuestion';
import AddOrEditTroubleshoot from '../../../modules/troubleshoot/pages/addOrEditTroubleshoot';
import QuizQuestionOverview from '../../../modules/quizQuestions/pages/quizQuestionOverview';
import { AddOrEditQuizRound } from '../../../modules/quizQuestions/pages/addOrEditQuizQuestion/AddorEditQuizRound';
import { troubleshootEndpoints } from '../../../modules/troubleshoot/troubleshoot.endpoints';
import TroubleshootOverview from '../../../modules/troubleshoot/pages/troubleshootOverview';
import { globalSearchEndpoints } from '../../../modules/globalSearch/global.search.endpoints';
import { GlobalSearchAppPages } from '../../../modules/globalSearch/pages';
import { loginConstants } from '../../../modules/login/login.constants';
import { analyticsConstants } from '../../../modules/analytics/analytics.constants';
import AnalyticsOverview from '../../../modules/analytics/analyticsOverview';
import InfluencerFeed from '../../../modules/influencers/pages/addOrEditInfluencer/InfluencerFeed';

const PrivateLayout: React.FC<ILayoutProps> = (props: PropsWithChildren<ILayoutProps>) => {
    return (
        <div>
            <PublicNavbar>
                {props.loggedIn ? (
                    <Switch>
                        <Route exact path="/" component={Recipe} />
                        <Route
                            exact
                            path={[
                                ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS,
                                `${ingredientsEndpoints.INGREDIENTS_ROUTES.INGREDIENTS}/:id`,
                            ]}
                            component={Ingredient}
                        />
                        <Route
                            exact
                            path={[
                                categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS,
                                `${categoryTagsEndpoints.CATEGORY_TAGS_ROUTES.CATEGORY_TAGS}/:id`,
                            ]}
                            component={CategoryTag}
                        />

                        <Route
                            exact
                            path={[
                                categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES,
                                `${categoryTagTypesEndpoints.CATEGORY_TAG_TYPES_ROUTES.CATEGORY_TAG_TYPES}/:id`,
                            ]}
                            component={CategoryTagTypes}
                        />

                        <Route
                            exact
                            path={[
                                actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES,
                                `${actionNamesConstants.ACTION_NAMES_ROUTES.ACTION_NAMES}/:id`,
                            ]}
                            component={ActionName}
                        />
                        <Route
                            exact
                            path={[
                                cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD,
                                `${cookingMethodsEndpoints.COOKING_METHOD_ROUTES.COOKING_METHOD}/:id`,
                            ]}
                            component={CookingMethods}
                        />
                        <Route exact path={tutorialConstants.TUTORIALS_ROUTES.TUTORIALS} component={Tutorial} />
                        <Route exact path={productConstants.PRODUCT_ROUTES.PRODUCTS} component={Product} />
                        <Route exact path={influencerConstants.INFLUENCER_ROUTES.INFLUENCERS} component={Influencer} />
                        <Route exact path="/upselling" component={Recipe} />
                        <Route exact path={recipeConstants.RECIPE_ROUTES.ADD_RECIPE} component={RecipeForm} />
                        <Route path={`${recipeConstants.RECIPE_ROUTES.EDIT_RECIPE}/:step/:id`} component={RecipeForm} />
                        <Route
                            exact
                            path={tutorialConstants.TUTORIALS_ROUTES.ADD_TUTORIAL}
                            component={AddOrEditTutorial}
                        />
                        <Route
                            path={`${tutorialConstants.TUTORIALS_ROUTES.EDIT_TUTORIAL}/:step/:id`}
                            component={AddOrEditTutorial}
                        />
                        <Route path={`${equipmentConstants.EQUIPMENT_ROUTES.EQUIPMENT}`} component={Equipment} />
                        <Route
                            exact
                            path={influencerConstants.INFLUENCER_ROUTES.ADD_INFLUENCER}
                            component={InfluencerForm}
                        />
                        <Route
                            path={`${influencerConstants.INFLUENCER_ROUTES.INFLUENCER_FEED}/:id`}
                            component={InfluencerFeed}
                        />
                        <Route
                            path={`${influencerConstants.INFLUENCER_ROUTES.EDIT_INFLUENCER}/:id`}
                            component={InfluencerForm}
                        />
                        <Route exact path={productConstants.PRODUCT_ROUTES.ADD_PRODUCT} component={ProductForm} />
                        <Route path={`${productConstants.PRODUCT_ROUTES.EDIT_PRODUCT}/:id`} component={ProductForm} />
                        <Route
                            exact
                            path={[unitConstants.UNIT_ROUTES.UNITS, `${unitConstants.UNIT_ROUTES.UNITS}/:id`]}
                            component={Units}
                        />
                        <Route exact path={filterConstants.FILTERS_ROUTES.FILTERS} component={Filter} />
                        <Route
                            path={`${filterConstants.FILTERS_ROUTES.EDIT_FILTER}/:step/:id`}
                            component={AddOrEditFilter}
                        />
                        <Route exact path={filterConstants.FILTERS_ROUTES.ADD_FILTER} component={AddOrEditFilter} />
                        <Route exact path={selectionConstants.SELECTION_ROUTES.SELECTIONS} component={Selections} />
                        <Route
                            exact
                            path={selectionConstants.SELECTION_ROUTES.ADD_SELECTION}
                            component={AddOrEditSelection}
                        />
                        <Route
                            path={`${selectionConstants.SELECTION_ROUTES.EDIT_SELECTION}/:step/:id`}
                            component={AddOrEditSelection}
                        />
                        <Route exact path={reviewConstants.REVIEW_ROUTES.REVIEWS} component={Review} />
                        <Route exact path={reviewConstants.REVIEW_ROUTES.EDIT_BLACKLIST} component={EditBlacklist} />
                        <Route
                            exact
                            path={promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.PROMOTION_CAMPAIGN}
                            component={PromotionCampaign}
                        />
                        <Route
                            exact
                            path={promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.ADD_PROMOTION_CAMPAIGN}
                            component={AddOrEditPromotionCampaign}
                        />
                        <Route
                            path={`${promotionCampaignEndpoints.PROMOTION_CAMPAIGN_ROUTES.EDIT_PROMOTION_CAMPAIGN}/:id`}
                            component={AddOrEditPromotionCampaign}
                        />
                        <Route
                            path={`${userReportConstants.USER_REPORT_ROUTES.REVIEW_REPORTS}/:reviewId`}
                            component={ReviewReportList}
                        />
                        <Route
                            exact
                            path={userReportConstants.USER_REPORT_ROUTES.REPORTED_USERS}
                            component={UserReports}
                        />
                        <Route
                            path={`${userReportConstants.USER_REPORT_ROUTES.REPORTS_FOR_USER}/:userId`}
                            component={ReportsForUser}
                        />
                        <Route
                            exact
                            path={referralOrderConstants.REFERRAL_ORDER_ROUTES.REFERRAL_ORDERS}
                            component={ReferralOrders}
                        />
                        <Route
                            exact
                            path={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_QUESTIONS}
                            component={QuizQuestionOverview}
                        />
                        <Route
                            exact
                            path={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_QUESTION}
                            component={AddOrEditQuizQuestion}
                        />
                        <Route
                            path={`${quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_QUESTION}/:id`}
                            component={AddOrEditQuizQuestion}
                        />
                        <Route
                            exact
                            path={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.QUIZ_ROUNDS}
                            component={QuizQuestionOverview}
                        />
                        <Route
                            exact
                            path={quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.ADD_QUIZ_ROUND}
                            component={AddOrEditQuizRound}
                        />
                        <Route
                            path={`${quizQuestionEndpoints.QUIZ_QUESTION_ROUTES.EDIT_QUIZ_ROUND}/:id`}
                            component={AddOrEditQuizRound}
                        />
                        <Route
                            exact
                            path={troubleshootEndpoints.ROUTES.TROUBLESHOOTS}
                            component={TroubleshootOverview}
                        />
                        <Route
                            exact
                            path={troubleshootEndpoints.ROUTES.ADD_TROUBLESHOOT}
                            component={AddOrEditTroubleshoot}
                        />
                        <Route
                            path={`${troubleshootEndpoints.ROUTES.EDIT_TROUBLESHOOT}/:step/:id`}
                            component={AddOrEditTroubleshoot}
                        />
                        <Route
                            exact
                            path={globalSearchEndpoints.ROUTES.GLOBAL_SEARCH_APP_PAGES}
                            component={GlobalSearchAppPages}
                        />
                        <Route
                            exact
                            path={analyticsConstants.ANALYTICS_ROUTES.ANALYTICS_OVERVIEW}
                            component={AnalyticsOverview}
                        />

                        <Route path="*" component={Recipe} />
                    </Switch>
                ) : (
                    <Redirect to={loginConstants.ROUTES.LOGIN} />
                )}
            </PublicNavbar>
            <Footer />
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    loggedIn: state.authentication.loggedIn,
});

export default compose(withRouter, connect(mapStateToProps, null))(PrivateLayout);
