import { Action, Middleware, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { actionNamesReducer } from '../modules/actionNames/action.names.reducer';
import { promotionCampaignReducer } from '../modules/campaigns/promotion.campaign.reducer';
import { categoryTagTypeReducer } from '../modules/categoryTagTypes/category.tag.types.reducer';
import { categoryTagsReducer } from '../modules/categoryTags/category.tags.reducer';
import { cookingMethodReducer } from '../modules/cookingMethods/cooking.method.reducer';
import { equipmentReducer } from '../modules/equipment/equipment.reducer';
import { filterReducer } from '../modules/filters/filter.reducer';
import { importReducer } from '../modules/import/import.reducer';
import { ingredientsReducer } from '../modules/ingredients/ingredients.reducer';
import { loginReducer } from '../modules/login/login.reducer';
import { quizQuestionReducer } from '../modules/quizQuestions/quiz.questions.reducer';
import { recipeReducer } from '../modules/recipe/recipe.reducer';
import { referralOrderReducer } from '../modules/referralOrders/referral.order.reducer';
import { reviewReducer } from '../modules/reviews/reviews.reducer';
import { selectionReducer } from '../modules/selection/selection.reducer';
import { troubleshootReducer } from '../modules/troubleshoot/troubleshoot.reducer';
import { tutorialsReducer } from '../modules/tutorials/tutorial.reducer';
import { unitReducer } from '../modules/unitManagement/unit.reducer';
import { upsellingReducer } from '../modules/upselling/upselling.reducer';
import { userReportsReducer } from '../modules/userReports/user.report.reducer';
import { alertReducer } from './reducers/alertReducer';
import { authenticationReducer } from './reducers/auth.reducer';
import { tokenReducer } from './reducers/tokenReducer';
import { userReducer } from './reducers/user.reducer';
import { influencerSlice } from '../modules/influencers/influencer.slice';
import { tokenService } from './services/token.service';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { analyticsSlice } from '../modules/analytics/analytics.slice';

const loggerMiddleware = createLogger();

const store = configureStore({
    reducer: {
        alert: alertReducer,
        user: userReducer,
        login: loginReducer,
        authentication: authenticationReducer,
        token: tokenReducer,
        recipe: recipeReducer,
        categoryTags: categoryTagsReducer,
        actionNames: actionNamesReducer,
        ingredients: ingredientsReducer,
        categoryTagTypes: categoryTagTypeReducer,
        import: importReducer,
        cookingMethods: cookingMethodReducer,
        tutorials: tutorialsReducer,
        filters: filterReducer,
        selections: selectionReducer,
        products: upsellingReducer,
        units: unitReducer,
        equipmentList: equipmentReducer,
        reviews: reviewReducer,
        promotionCampaigns: promotionCampaignReducer,
        userReports: userReportsReducer,
        referralOrders: referralOrderReducer,
        quizQuestions: quizQuestionReducer,
        troubleshoot: troubleshootReducer,
        analytics: analyticsSlice.reducer,
        influencers: influencerSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware, loggerMiddleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const storeDispatch = store.dispatch;

export default store;

tokenService.injectStore(store);
